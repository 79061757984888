import React, { useCallback, useEffect, useState } from "react"
import Tree from "rc-tree"
import "rc-tree/assets/index.css"
import { Button, Div, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { DropdownArrowIcon } from "@ikiru/talentis-fpc"
import {
  SwitcherIconStyled,
  AssignAttributesModalWrapper,
  GlobalStyles,
  StyledBodyWrapper
} from "../../styles"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { assignAttributes, getAllAttributes } from "../../actions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import AssignAttributesAccordion from "./AssignAttributesAccordion"
import {
  addIsCheckedFlag,
  filterCheckedNodes,
  getCheckedKeys,
  getCheckedLeafNodeIds,
  isLeafNode,
  setCheckedFlag
} from "../../helper"

const AssignAttributes = () => {
  const [allAttributes, setAllAttributes] = useState<any>()
  const [valuesChecked, setValuesChecked] = useState<any>()
  const [checkedTreeKeys, setCheckedTreeKeys] = useState([])

  const { attributes, setAttributes, personId } = usePerson()

  const { close } = useModal()

  const closeAssignAttributes = () => {
    close(ModalNames.AssignAttributes)
  }

  const saveChanges = () => {
    const checkedLeafNodeIds = getCheckedLeafNodeIds(valuesChecked)
    const updateAttributes = assignAttributes(personId, checkedLeafNodeIds)

    setAttributes(filterCheckedNodes(valuesChecked))
    close(ModalNames.AssignAttributes)
  }

  useEffect(() => {
    if (allAttributes?.length > 0) {
      const attributesWitchChecked = addIsCheckedFlag(allAttributes, attributes)
      const checkedKeysTemp = getCheckedKeys(attributesWitchChecked) || []

      setValuesChecked(attributesWitchChecked)
      setCheckedTreeKeys(checkedKeysTemp)
    }
  }, [allAttributes, attributes])

  useEffect(() => {
    let isAttributesLoading = true

    const fetchAttributes = async () => {
      try {
        const attributes = await getAllAttributes()
        if (isAttributesLoading) {
          setAllAttributes(attributes)
        }
      } catch (error) {
        console.error("Error fetching attributes:", error)
      }
    }

    fetchAttributes()

    return () => {
      isAttributesLoading = false
    }
  }, [])

  const onCheck = useCallback(
    (checkedKeys, info) => {
      const leafKeys = checkedKeys.filter((key) =>
        isLeafNode(key, allAttributes)
      )
      setCheckedTreeKeys(leafKeys)

      setValuesChecked((prevValuesChecked) =>
        setCheckedFlag(prevValuesChecked, checkedKeys)
      )
    },
    [checkedTreeKeys, allAttributes]
  )

  const removeAttributes = useCallback(
    (key: string) => {
      const tempCheckedKeys = checkedTreeKeys.filter(
        (checkedTreeKey) => checkedTreeKey !== key
      )

      setCheckedTreeKeys(tempCheckedKeys)
      setValuesChecked((prevValuesChecked) =>
        setCheckedFlag(prevValuesChecked, tempCheckedKeys)
      )
    },
    [checkedTreeKeys, setCheckedTreeKeys, setValuesChecked]
  )

  return (
    <AssignAttributesModalWrapper>
      <ModalSectionHeader
        title="Assign  Attributes"
        size="xSmall"
        actions={
          <>
            <Button size="small" mr="xxs" onClick={() => saveChanges()}>
              {messages.form.generic.save}
            </Button>
            <Button
              mode="standard-white"
              size="small"
              type="button"
              onClick={closeAssignAttributes}
            >
              {messages.generic.cancel}
            </Button>
          </>
        }
      />

      <StyledBodyWrapper>
        <Div>
          <GlobalStyles />
          <Tree
            treeData={allAttributes}
            checkable
            checkedKeys={checkedTreeKeys}
            onCheck={onCheck}
            switcherIcon={
              <SwitcherIconStyled>
                <DropdownArrowIcon width={8} height={8} />
              </SwitcherIconStyled>
            }
            showIcon={false}
          />
        </Div>

        <AssignAttributesAccordion
          attributes={valuesChecked}
          remove={removeAttributes}
        />
      </StyledBodyWrapper>
    </AssignAttributesModalWrapper>
  )
}

export default AssignAttributes
