import React from "react"
import { Flex, H5, Tag, BodyText, Div } from "@ikiru/talentis-fpc"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import { StyledPlusIcon } from "views/internal-search/components/SearchField/styles"
import { ModalNames } from "setup/modal/modal.definitions"

import { useModal } from "utils/hooks/use-modal"
import AssignAttributes from "../assign-attributes/AssignAttributes"

type AttributesListTreeProps = {
  attributes: any
  remove: (key: string) => void
}

const AttributesListTree = ({
  attributes,
  remove
}: AttributesListTreeProps) => {
  const { openInPortal } = useModal()

  const assignAttributes = () => {
    openInPortal(ModalNames.AssignAttributes, <AssignAttributes />)
  }

  const renderTree = (nodes: any, path = "") => {
    return nodes.map((node: any) => {
      const currentPath = path ? `${path} • ${node.title}` : node.title

      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        if (node.children.every((child: any) => !child.children)) {
          return (
            <BodyText my="0" key={node.key}>
              {currentPath} {"•"}
              {node.children.map((leaf: any) => (
                <Tag
                  ml="5px"
                  key={leaf.key}
                  variant="grey-light"
                  onRemoveClick={() => remove(leaf.key)}
                >
                  {leaf.title}
                </Tag>
              ))}
            </BodyText>
          )
        }

        return (
          <React.Fragment key={node.key}>
            {renderTree(node.children, currentPath)}
          </React.Fragment>
        )
      }

      return (
        <Tag
          key={node.key}
          variant="grey-light"
          onRemoveClick={() => remove(node.key)}
        >
          {currentPath}
        </Tag>
      )
    })
  }

  return (
    <>
      {attributes.map((attribute: any) => (
        <Div key={attribute.key} mb="20px">
          <Flex alignItems="center" key={attribute.key} mb="10px">
            <H5 my="0" mr="5px">
              {attribute.title}
            </H5>
            <StyledRemoveButton
              mode="standard-white"
              size="action-small"
              onClick={assignAttributes}
            >
              <StyledPlusIcon />
            </StyledRemoveButton>
          </Flex>
          {renderTree(attribute.children || [])}
        </Div>
      ))}
    </>
  )
}

export default AttributesListTree
