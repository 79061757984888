import React, { useEffect, useMemo } from "react"
import { Loader } from "components/Loader/Loader"
import { PersonDocuments } from "views/persons/components/person-documnets"
import { PersonDetails } from "views/persons/components/person-details"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonAssignment } from "views/persons/components/person-assignments"
import { PersonNotes } from "views/persons/components/person-notes"
import { PersonExperience } from "views/persons/components/person-experience"
import { PersonCampaign } from "views/persons/components/person-campaigns"
import { Flex } from "@ikiru/talentis-fpc"
import { e2eTargets } from "./definitions"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import useTabLoader from "./hooks/useTabLoader"
import PersonDetailsTab from "views/persons/components/person-details-tab/PersonDetailsTab"
import PersonRecordDates from "views/persons/components/PersonRecordDates/PersonRecordDates"
import PersonOtherInfo from "views/persons/components/person-other-info/PersonOtherInfo"
import { PersonAttributes } from "views/persons/components/person-keywords"

export const PersonProfile = () => {
  const {
    isLoading,
    personId,
    profileDetails,
    getProfileData,
    createdDate,
    notes = []
  } = usePerson()

  const lastNoteDate = useMemo(
    () => (notes.length ? notes[0].createdDate : undefined),
    [notes]
  )

  const {
    assignmentLength,
    assignmentProps,
    campaignLength,
    campaignProps,
    isLoadingDocuments,
    documentLength,
    isNotesLoading,
    noteLength,
    assignmentNoteOptions,
    campaignNoteOptions
  } = useTabLoader()

  useEffect(() => {
    getProfileData(personId)
  }, [personId, getProfileData])

  return isLoading ? (
    <Loader />
  ) : (
    <Flex flexDirection="column">
      <Helmet>
        <title>{messages.seo.title.format(profileDetails?.name?.value)}</title>
      </Helmet>
      <Flex data-e2e-target={e2eTargets.profile} flexWrap="wrap">
        <PersonDetails
          personLinkAssignmentProps={assignmentProps}
          personLinkCampaignProps={campaignProps}
        />
        <Tabs>
          <Tab title={messages.person.personTabHeaders.personDetails}>
            <PersonDetailsTab />
          </Tab>
          <Tab
            defaultSelected
            title={messages.person.personTabHeaders.professionalExperience}
          >
            <PersonExperience />
          </Tab>
          <Tab
            title={`${messages.person.personTabHeaders.assignment}${
              assignmentLength > 0 ? messages.person.personTabHeaders.s : ""
            } (${assignmentLength})`}
          >
            <PersonAssignment {...assignmentProps} />
          </Tab>
          <Tab
            title={`${messages.person.personTabHeaders.campaigns}${
              campaignLength > 0 ? messages.person.personTabHeaders.s : ""
            } (${campaignLength})`}
          >
            <PersonCampaign {...campaignProps} />
          </Tab>
          <Tab
            title={`${messages.person.personTabHeaders.notes}${
              noteLength > 0 ? messages.person.personTabHeaders.s : ""
            } (${noteLength})`}
          >
            <PersonNotes
              isNotesLoading={isNotesLoading}
              assignmentNoteOptions={assignmentNoteOptions}
              campaignNoteOptions={campaignNoteOptions}
            />
          </Tab>
          <Tab
            title={`${messages.person.personTabHeaders.document}${
              documentLength > 0 ? messages.person.personTabHeaders.s : ""
            } (${documentLength})`}
          >
            <PersonDocuments isLoadingDocuments={isLoadingDocuments} />
          </Tab>
          <Tab title={messages.person.personTabHeaders.attributes}>
            <PersonAttributes />
          </Tab>

          <Tab title={messages.person.personTabHeaders.other}>
            <PersonOtherInfo />
          </Tab>
        </Tabs>
      </Flex>
      <PersonRecordDates
        createdDate={createdDate}
        lastNoteDate={lastNoteDate}
      />
    </Flex>
  )
}
