export const removeNodeByKey = (attributes: any, keyToRemove: string) => {
  return attributes.filter((node: any) => {
    if (node.key === keyToRemove) {
      return false
    }
    if (node.children) {
      node.children = removeNodeByKey(node.children, keyToRemove)
    }
    return true
  })
}

export const getLastNodeIds = (attributes: any) => {
  const lastNodeIds: string[] = []

  const traverse = (nodes) => {
    nodes.forEach((node) => {
      if (!node.children || node.children.length === 0) {
        if (node.id || node.Id) {
          lastNodeIds.push(node.id || node.Id)
        }
      } else {
        traverse(node.children)
      }
    })
  }

  traverse(attributes)
  return lastNodeIds
}

export const getCheckedLeafNodeIds = (nodes) => {
  const result: string[] = []

  const traverse = (node) => {
    if (node.isChecked && (!node.children || node.children.length === 0)) {
      result.push(node.id)
    }

    if (node.children) {
      node.children.forEach(traverse)
    }
  }

  nodes.forEach(traverse)

  return result
}

export const filterCheckedNodes = (nodes) => {
  return nodes
    .filter(
      (node) =>
        node.isChecked ||
        (node.children &&
          node.children.some((child) => filterCheckedNodes([child]).length > 0))
    )
    .map((node) => ({
      ...node,
      children: node.children ? filterCheckedNodes(node.children) : []
    }))
}

export const addIsCheckedFlag = (nodes, checkedNodes) => {
  const checkedMap = new Map()

  const createCheckedMap = (nodes) => {
    nodes.forEach((node) => {
      checkedMap.set(node.key, true)
      if (node.children) createCheckedMap(node.children)
    })
  }

  createCheckedMap(checkedNodes)

  const addFlag = (nodes) => {
    return nodes.map((node) => {
      const newNode = { ...node, isChecked: checkedMap.has(node.key) }

      if (newNode.children) {
        newNode.children = addFlag(newNode.children)
      }
      return newNode
    })
  }

  return addFlag(nodes)
}

export const setCheckedFlag = (nodes, keys) => {
  return nodes.map((node) => {
    node.isChecked = keys.includes(node.key)

    if (node.children) {
      node.children = setCheckedFlag(node.children, keys)

      node.isChecked =
        node.isChecked || node.children.some((child) => child.isChecked)
    }

    return node
  })
}

export const isLeafNode = (nodeKey, treeData) => {
  const findNode = (key, nodes) => {
    for (const node of nodes) {
      if (node.key === key) {
        return node
      }
      if (node.children) {
        const found = findNode(key, node.children)
        if (found) return found
      }
    }
    return null
  }

  const node = findNode(nodeKey, treeData)
  return node && (!node.children || node.children.length === 0)
}

export const getCheckedKeys = (nodes) => {
  const checkedKeys = []

  const traverse = (nodes) => {
    nodes.forEach((node) => {
      if (node.children) {
        traverse(node.children)
      }

      if (!node.children && node.isChecked) {
        checkedKeys.push(node.key)
      }
    })
  }

  traverse(nodes)
  return checkedKeys
}
