import {
  CVDataType,
  CVProfessionalExperience,
  Education,
  Experience,
  Person
} from "views/persons/person.types"

export const createCvExistingColumn = (
  exp: Experience | Education,
  type: CVDataType,
  isEmptyProfile = false
) => {
  let extendExp = exp as any
  extendExp.changedValues = {}
  extendExp.type = type
  if (type !== CVDataType.Parsed) {
    extendExp.isMovedToParsed = false
  } else {
    extendExp.isMovedToExisting = isEmptyProfile
  }
  return extendExp
}

export const getCVExperience = (
  data: Person,
  person: Person | undefined,
  isEmptyProfile: boolean
): CVProfessionalExperience => {
  let parsed =
    data.experience
      .filter(
        (parsedExp) =>
          !Boolean(
            person?.experience.filter((exp) => exp.id === parsedExp.id).length
          )
      )
      .map((exp) =>
        createCvExistingColumn(exp, CVDataType.Parsed, isEmptyProfile)
      ) || []

  let existing = []
  // person?.experience.map((exp) =>
  //   createCvExistingColumn(exp, CVDataType.Existing)
  // ) || []

  const returnData: CVProfessionalExperience = { existing, parsed }

  return returnData
}
