import React, { useState } from "react"
import { colors, H5 } from "@ikiru/talentis-fpc"
import {
  DropDownIconStyled,
  BodyAccordion,
  HeaderWrapper,
  StyledAccordion
} from "./styles"

export type AccordionProps = {
  title: string
  isOpen?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const Accordion = ({
  title,
  isOpen = false,
  children,
  onClick
}: AccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen)

  return (
    <StyledAccordion>
      <HeaderWrapper
        onClick={() => {
          setIsAccordionOpen((prevValue) => !prevValue)
          onClick && onClick()
        }}
      >
        <H5 mt="10px" color={colors.grey.dark}>
          {title}
        </H5>
        <DropDownIconStyled up={isAccordionOpen} />
      </HeaderWrapper>
      {isAccordionOpen && <BodyAccordion>{children}</BodyAccordion>}
    </StyledAccordion>
  )
}

export default Accordion
