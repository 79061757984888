import React, { useMemo } from "react"
import { BodyText, Flex, Tag } from "@ikiru/talentis-fpc"
import Accordion from "../accordion"

type AssignAttributesAccordionProps = {
  attributes: any
  remove: (key: string) => void
}

const AssignAttributesAccordion = ({
  attributes,
  remove
}: AssignAttributesAccordionProps) => {
  const renderTree = (nodes, path = "") => {
    return nodes.map((node) => {
      const currentPath = path ? `${path} • ${node.title}` : node.title

      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        if (node.children.every((child) => !child.children)) {
          const checkedChildren = node.children.filter(
            (child) => child.isChecked
          )

          if (checkedChildren.length > 0) {
            return (
              <BodyText mt="0" mb="0" fontSize="14px" key={node.key}>
                {currentPath} {"•"}
                {checkedChildren.map((leaf) => (
                  <Tag
                    ml="5px"
                    mb="5px"
                    key={leaf.key}
                    variant="grey-light"
                    onRemoveClick={() => remove(leaf.key)}
                  >
                    {leaf.title}
                  </Tag>
                ))}
              </BodyText>
            )
          }

          return null
        }

        const children = renderTree(node.children, currentPath)

        if (children) {
          return <React.Fragment key={node.key}>{children}</React.Fragment>
        }

        return null
      }

      if (node.isChecked && !node.children) {
        return (
          <Tag
            key={node.key}
            variant="grey-light"
            onRemoveClick={() => remove(node.key)}
          >
            {currentPath}
          </Tag>
        )
      }

      return null
    })
  }

  const accordionItems = useMemo(() => {
    return attributes?.map((attribute) => ({
      title: `${attribute.title} `,
      children: renderTree(attribute.children || [])
    }))
  }, [attributes])

  return (
    <Flex flexDirection="column" marginLeft="80px" width="100%">
      {accordionItems?.map((item, index) => (
        <Accordion key={index} title={item.title} children={item.children} />
      ))}
    </Flex>
  )
}

export default AssignAttributesAccordion
