import styled from "styled-components"
import { colors, Div, Flex, spacing } from "@ikiru/talentis-fpc"
import { createGlobalStyle } from "styled-components"
import { scrollBarCvStyle } from "components/FPCComponents/DragAndDrop/Columns/style"

export const StyledTreeWrapper = styled(Div)`
  .rc-tree-switcher {
    display: none;
  }
`

export const GlobalStyles = createGlobalStyle`

.rc-tree-title {
  margin-left: 5px;
  font-family: 'canada-type-gibson';
  font-size: 13px;
}
  /* Hide default rc-tree switcher */
  .rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_open {
    background-position: -62px -56px;
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_close {
    background-position: -62px -56px;
    svg {
    transform: rotate(-90deg);
    }

}

.rc-tree-checkbox {
  background-color: #007bff; /* Задаёт цвет фона */
  
  width: 18px !;  /* Ширина чекбокса */
  height: 18px; /* Высота чекбокса */
}
.rc-tree-checkbox-checked {
  background-color: #28a745; /* Цвет для активного состояния */
}

.rc-tree-node-selected {
    background-color: ${colors.green.lightest};
    box-shadow: 0 0 0 1px ${colors.green.lightest};
    opacity: 0.8;
}
`

export const SwitcherIconStyled = styled(Div)`
  padding-left: 4px;
  position: relative;
  bottom: 4px;
`

export const AssignAttributesModalWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 70vw;
  height: 70vh;
`

export const StyledBodyWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  ${scrollBarCvStyle}
`
