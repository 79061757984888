import { apiRequest } from "setup/api/api"
import {
  PersonsEndpoints,
  AttributesEndpoints
} from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const newValues = {
  Id: "6898ca64-7ac7-4262-83b8-fc1b58f10fe0",
  Title: "Attributes",
  Key: "0",
  Description: null,
  WhereUsed: "Hidden",
  IsAttribute: false,
  Children: [
    {
      id: "d06a8d40-52e0-4078-aa9a-35b14e552629",
      title: "Industries",
      key: "0-0",
      Description: null,
      WhereUsed: ["person", "candidate"],
      IsAttribute: true,
      children: [
        {
          title: "Procurement and Supply Chain",
          id: "d06a8d40-52e0-4078-aa9a-35b14e54444629",
          key: "0-0-1",
          children: [
            {
              title: "Logistics",
              id: "d06a8d40-52e0-4rrrrrr35b14e552629",
              key: "0-0-1-905"
            }
          ]
        },
        {
          title: "Transportation",
          id: "d06a8d40-52e0333339a222222-35b14e552629",
          key: "0-0-2",
          children: [
            {
              title: "Air Transportation",
              id: "d06a8d40-52e0333339a-35b14e552629",
              key: "0-0-2-273",
              children: [
                {
                  title: "Commercial Air Transport",
                  id: "d06a8d40-52eeeeeee0-4078-aa9a-35b14e552629",
                  key: "0-0-2-273-151"
                },
                {
                  title: "Commercial Air Transport 11111",
                  id: "d06a8d40-52eeeeeee0-4078-a1111a9a-35b14e552629",
                  key: "0-0-2-273-152"
                },
                {
                  title: "Commercial Air Transport 2222",
                  id: "d06a8d40-52eeeeeee0-4078-a122221a9a-35b14e552629",
                  key: "0-0-2-273-153"
                }
              ]
            },
            {
              title: "Marine Transportation",
              id: "d0eeeeeee6a8d40-52e0333339a-35b14e552629",
              key: "0-0-2-274",
              children: [
                {
                  title: "Commercial Shipping",
                  id: "d06a8d40-52e0-4erere078-aa9a-35b14e552629",
                  key: "0-0-2-274-151"
                },
                {
                  title: "Passenger Ferries, Cruise Shipping",
                  id: "d033336a8d40-52e0-4078-aa9a-35b14e552629",
                  key: "0-0-2-274-152"
                },
                {
                  title: "Passenger Ferries, Cruise Shipping 33333",
                  id: "d033336a8d40-52e0-4078-aa9a-35b333314e552629",
                  key: "0-0-2-274-153"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: "848c2edc-06a7-4f61-86a2-6df4dbe2de02",
      title: "Industries Other",
      key: "0-1",
      Description: null,
      WhereUsed: ["person", "candidate", "company"],
      IsAttribute: false,
      children: [
        {
          id: "a76e2ebf-836d-48bc-b05f-251e56143093",
          title: "Target Company",
          key: "0-1-0",
          Description: null,
          WhereUsed: ["company"],
          IsAttribute: true,
          children: [
            {
              title: "Target Company 111111",
              id: "d033336a8d40-5111178-aa9a-35b14e552629",
              key: "0-1-0-1"
            },
            {
              title: "Target Company 22222",
              id: "d033336a8d40-22222-aa9a-35b14e552629",
              key: "0-1-0-2"
            }
          ]
        },
        {
          id: "a76e2ebf-836d-48bc-b05f-251e56143093",
          title: "Building",
          key: "0-1-1",
          Description: null,
          WhereUsed: ["person", "candidate"],
          IsAttribute: true,
          children: [
            {
              title: "Building 22222",
              id: "d033336a8d40-22211122-aa9a-35b14e552629",
              key: "0-1-1-1"
            },
            {
              title: "Building 1111",
              id: "d033336a8d40-1111-aa9a-35b14e552629",
              key: "0-1-1-2"
            }
          ]
        },
        {
          id: "ecc0f5d8-14c4-442a-9bc4-dbdd5e276836",
          title: "Legal",
          key: "0-1-2",
          Description: null,
          WhereUsed: ["person", "candidate"],
          IsAttribute: true
        },
        {
          id: "7ce31f5b-5543-44e9-a84c-68da6a57b6b4",
          title: "Retail",
          key: "0-1-3",
          Description: null,
          WhereUsed: ["person", "candidate"],
          IsAttribute: true,
          children: [
            {
              title: "Retail 1111",
              id: "d033336a8d40-13443333111-aa9a-35b14e552629",
              key: "0-1-3-1"
            },
            {
              title: "Retail 222",
              id: "d033336a8d40-11333311-aa9a-35b14e552629",
              key: "0-1-3-2"
            }
          ]
        }
      ]
    }
  ]
}

export const values = [
  {
    title: "Industry",
    key: "0-0",
    id: "0-0",
    children: [
      {
        title: "Technology",
        key: "0-0-1",
        id: "0-0-1",
        children: [
          {
            title: "AI",
            key: "0-0-1-1",
            id: "0-0-1-1"
          },
          {
            title: "SaaS",
            key: "0-0-1-2",
            id: "0-0-1-2",
            children: [
              {
                title: "B2B",
                key: "0-0-1-2-1",
                id: "0-0-1-2-1"
              },
              {
                title: "B2C",
                key: "0-0-1-2-2",
                id: "0-0-1-2-2"
              }
            ]
          }
        ]
      },
      {
        title: "Financial Services",
        key: "0-0-2",
        id: "0-0-2"
      },
      {
        title: "Professional Services",
        key: "0-0-3",
        id: "0-0-3"
      }
    ]
  },
  {
    title: "Function",
    key: "0-1",
    id: "0-1",
    children: [
      {
        title: "Board",
        key: "0-1-1",
        id: "0-1-1",
        children: [
          {
            title: "Chair",
            key: "0-1-1-1",
            id: "0-1-1-1"
          },
          {
            title: "Senior NED",
            key: "0-1-1-2",
            id: "0-1-1-2"
          },
          {
            title: "NED",
            key: "0-1-1-3",
            id: "0-1-1-3"
          },
          {
            title: "Remco",
            key: "0-1-1-4",
            id: "0-1-1-4"
          },
          {
            title: "Audit Co",
            key: "0-1-1-5",
            id: "0-1-1-5"
          }
        ]
      },
      {
        title: "C-Level",
        key: "0-1-2",
        id: "0-1-2",
        children: [
          {
            title: "Chief Executive",
            key: "0-1-2-1",
            id: "0-1-2-1"
          },
          {
            title: "Chief Financial",
            key: "0-1-2-2",
            id: "0-1-2-2"
          },
          {
            title: "Chief Marketing",
            key: "0-1-2-3",
            id: "0-1-2-3"
          },
          {
            title: "Chief People",
            key: "0-1-2-4",
            id: "0-1-2-4"
          }
        ]
      },
      {
        title: "Manager",
        key: "0-1-3",
        id: "0-1-3"
      }
    ]
  },
  {
    title: "Qualifications",
    key: "0-2",
    id: "0-2"
  },
  {
    title: "International Experience",
    key: "0-3",
    id: "0-3"
  },
  {
    title: "Languages",
    key: "0-4",
    id: "0-4",
    children: [
      {
        title: "English",
        key: "0-4-1",
        id: "0-4-1",
        children: [
          {
            title: "English Fluent",
            key: "0-4-1-1",
            id: "0-4-1-1"
          },
          {
            title: "English Working",
            key: "0-4-1-2",
            id: "0-4-1-2"
          }
        ]
      },
      {
        title: "Spanish",
        key: "0-4-2",
        id: "0-4-2",
        children: [
          {
            title: "Spanish Fluent",
            key: "0-4-2-1",
            id: "0-4-2-1"
          },
          {
            title: "Spanish Working",
            key: "0-4-2-2",
            id: "0-4-2-2"
          }
        ]
      }
    ]
  },
  {
    title: "Custom",
    key: "0-5",
    id: "0-5",
    children: [
      {
        title: "Whatever",
        key: "0-5-1",
        id: "0-5-1"
      },
      {
        title: "You",
        key: "0-5-2",
        id: "0-5-2"
      },
      {
        title: "Want",
        key: "0-5-3",
        id: "0-5-3"
      }
    ]
  }
]

export const assignAttributes = async (personId: string, ids: string[]) => {
  const [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.PersonAttributes,
    data: { ids },
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const getAllAttributes = async () => {
  const [, response] = await apiRequest.get({
    endpoint: AttributesEndpoints.Root,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return values //response?.data
}
