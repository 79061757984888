import React from "react"
import PersonSections from "../person-components/PersonSections"
import AttributesList from "./components/attributes-list/AttributesList"

export const PersonAttributes = () => {
  return (
    <PersonSections title="Attributes">
      <AttributesList />
    </PersonSections>
  )
}
